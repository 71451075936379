.experience-accord {
  margin: 50px;
  /* padding: 0 50px; */
}

.experience-accord-ui {
  padding: 0 !important;
}

.accord-panel {
  font-family: "Google Sans Regular";
  padding: 0 !important;
}

@media (max-width: 768px) {
  .experience-accord {
    margin: 20px;
  }
}
